import {
  APPLICATION_FORMS,
  INTERVIEW_LIST,
  REFERRAL_CODES_USAGE,
} from "@/rbac/links.js";
import { VIEW_APPLICATION_FORM } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_username ||
    route.query.order_by_family_full_name ||
    route.query.order_by_email ||
    route.query.order_by_country_name ||
    route.query.order_by_type_name ||
    route.query.order_by_status_name ||
    route.query.order_by_come_from_name ||
    route.query.order_by_email_sent_name ||
    route.query.order_by_price ||
    route.query.order_by_updated_at
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    country_id: parseInt(route.query.country_id) || null,
    order_by_country_name: route.query.order_by_country_name || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    come_from_id: parseInt(route.query.come_from_id) || null,
    order_by_come_from_name: route.query.order_by_come_from_name || null,

    email_sent: parseInt(route.query.email_sent) || null,
    order_by_email_sent_name: route.query.order_by_email_sent_name || null,

    price: route.query.price || null,
    order_by_price: route.query.order_by_price || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  };
}

function referralCodesProps(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_username ||
    route.query.order_by_referring_username ||
    route.query.order_by_referral_code ||
    route.query.order_by_family_full_name ||
    route.query.order_by_referring_family_full_name ||
    route.query.order_by_email ||
    route.query.order_by_referring_email ||
    route.query.order_by_updated_at
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    referring_username: route.query.referring_username || null,
    order_by_referring_username:
      route.query.order_by_referring_username || null,

    referral_code: route.query.referral_code || null,
    order_by_referral_code: route.query.order_by_referral_code || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    referring_family_full_name: route.query.referring_family_full_name || null,
    order_by_referring_family_full_name:
      route.query.order_by_referring_family_full_name || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    referring_email: route.query.referring_email || null,
    order_by_referring_email: route.query.order_by_referring_email || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  };
}

function interviewListProps(route) {
  let orderByDefault = route.query.order_by_updated_at || "desc";

  if (
    route.query.order_by_username ||
    route.query.order_by_family_full_name ||
    route.query.order_by_email ||
    route.query.order_by_phone ||
    route.query.order_by_state ||
    route.query.order_by_date_of_birth ||
    route.query.order_by_country_name ||
    route.query.order_by_type_name ||
    route.query.order_by_come_from_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    phone: route.query.phone || null,
    order_by_phone: route.query.order_by_phone || null,

    state: route.query.state || null,
    order_by_state: route.query.order_by_state || null,

    country_id: parseInt(route.query.country_id) || null,
    order_by_country_name: route.query.order_by_country_name || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    come_from_id: parseInt(route.query.come_from_id) || null,
    order_by_come_from_name: route.query.order_by_come_from_name || null,

    order_by_updated_at: orderByDefault,
    order_by_date_of_birth: route.query.order_by_date_of_birth || null,
  };
}

const applicationFormRoutes = [
  {
    path: "/application-forms",
    name: "ApplicationFormIndex",
    meta: { title: "Application Forms", permission: APPLICATION_FORMS },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "application-form-index" */ "@/views/application-form/Index.vue"
      ),
  },
  {
    path: "/referral-codes",
    name: "ReferralCodesUsageIndex",
    meta: { title: "Referral Codes Usage", permission: REFERRAL_CODES_USAGE },
    props: (route) => referralCodesProps(route),
    component: () =>
      import(
        /* webpackChunkName: "referral-code-index" */ "@/views/referral-code/Index.vue"
      ),
  },
  {
    path: "/interview-list",
    name: "InterviewListIndex",
    meta: { title: "Interview List", permission: INTERVIEW_LIST },
    props: (route) => interviewListProps(route),
    component: () =>
      import(
        /* webpackChunkName: "application-form-interview-list" */ "@/views/application-form/InterviewList.vue"
      ),
  },
  {
    path: "/application-forms/:id/view",
    name: "ApplicationFormView",
    meta: { title: "View Application Form", permission: VIEW_APPLICATION_FORM },
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "application-form-view" */ "@/views/application-form/View.vue"
      ),
  },
];

export default applicationFormRoutes;
