import {
  ANNOUNCEMENT_TYPES,
  ANNOUNCEMENT_MESSAGE_TYPES,
  APPLICATION_FORM_BEHAVIOR_DISORDERS,
  APPLICATION_FORM_CATEGORIES,
  APPLICATION_FORM_CLIENT_LIVE_WITH_OPTIONS,
  APPLICATION_FORM_COGNITIVE_SKILLS,
  APPLICATION_FORM_COME_FROM_OPTIONS,
  APPLICATION_FORM_COMMON_ANSWERS,
  APPLICATION_FORM_COMPLETED_BY_OPTIONS,
  APPLICATION_FORM_DISCOUNT_NAMES,
  APPLICATION_FORM_DISCOUNT_TYPES,
  APPLICATION_FORM_EDUCATIONAL_PLACEMENTS,
  APPLICATION_FORM_GUIDES,
  APPLICATION_FORM_HAND_PREFERENCE_ABILITIES,
  APPLICATION_FORM_HAND_PREFERENCE_HANDS,
  APPLICATION_FORM_HEALTH_EARS,
  APPLICATION_FORM_HEALTH_EYE_DISEASES,
  APPLICATION_FORM_HEALTH_FOOD_ALLERGIES,
  APPLICATION_FORM_HEALTH_PRODUCTS,
  APPLICATION_FORM_HEALTH_SPECIALISTS,
  APPLICATION_FORM_HEALTH_TIME_PERIODS,
  APPLICATION_FORM_INFO_TYPES,
  APPLICATION_FORM_LANGUAGE_SKILLS,
  APPLICATION_FORM_MATH_SKILLS,
  APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS,
  APPLICATION_FORM_STATUSES,
  APPLICATION_FORM_TYPES,
  CHAPTERS,
  CLIENT_LIVE_WITH_OPTIONS,
  CLIENT_STATUSES,
  CONTACTS,
  COURSE_STATUSES,
  DAYS,
  DEVELOPMENTAL_PROFILE_CATEGORIES,
  DEVELOPMENTAL_PROFILE_DESCRIPTIONS,
  DOCUMENT_TYPES,
  EDUCATIONS,
  EVALUATION_TYPES,
  EVALUATION_VIDEO_TAGS,
  FAMILY_STATUSES,
  HEARD_FROM_OPTIONS,
  LANGUAGES,
  LIMBO_ARCHIVE_REASONS,
  MONTHS,
  PROGRAM_CODES,
  PROGRAM_TYPES,
  RE_EVALUATION_ASSISTANTS,
  RE_EVALUATION_PROCESSING_ACTIVITY_TYPES,
  RE_EVALUATION_STATUSES,
  RE_EVALUATION_TIME_PERIODS,
  REPORT_STATUSES,
  REPORT_TYPES,
  WHY_DROPPED_REASONS,
  THEMES,
  SCHEDULE_TYPES,
  UPCOMING_EVALUATION_TIME_ZONES,
  HOURS,
  MINUTES,
  TIME_PERIODS,
} from "@/rbac/links.js";

import { idNameProps, idNameCategoryProps } from "@/services/props.js";

const dropdownRoutes = [
  {
    path: "",
    name: "DropdownLayout",
    component: () =>
      import(
        /* webpackChunkName: "dropdown-layout" */ "@/views/dropdown/Layout.vue"
      ),
    children: [
      {
        path: "/announcement-types",
        name: "AnnouncementTypeIndex",
        meta: {
          title: "Announcement Types",
          permission: ANNOUNCEMENT_TYPES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "announcement-type-index" */ "@/views/dropdown/AnnouncementType.vue"
          ),
      },
      {
        path: "/announcement-message-types",
        name: "AnnouncementMessageTypeIndex",
        meta: {
          title: "Announcement Message Types",
          permission: ANNOUNCEMENT_MESSAGE_TYPES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "announcement-message-type-index" */ "@/views/dropdown/AnnouncementMessageType.vue"
          ),
      },
      {
        path: "/application-form-behaviour-disorders",
        name: "ApplicationFormBehaviourDisorderIndex",
        meta: {
          title: "Application Form Behavior Disorders",
          permission: APPLICATION_FORM_BEHAVIOR_DISORDERS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-behaviour-disorder-index" */ "@/views/dropdown/ApplicationFormBehaviourDisorder.vue"
          ),
      },
      {
        path: "/application-form-categories",
        name: "ApplicationFormCategoryIndex",
        meta: {
          title: "Application Form Categories",
          permission: APPLICATION_FORM_CATEGORIES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-category-index" */ "@/views/dropdown/ApplicationFormCategory.vue"
          ),
      },
      {
        path: "/application-form-client-live-with",
        name: "ApplicationFormClientLiveWithIndex",
        meta: {
          title: "Application Form Client Live With Options",
          permission: APPLICATION_FORM_CLIENT_LIVE_WITH_OPTIONS,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-client-live-with" */ "@/views/dropdown/ApplicationFormClientLiveWith.vue"
          ),
      },
      {
        path: "/application-form-cognitive-skills",
        name: "ApplicationFormCognitiveSkillIndex",
        meta: {
          title: "Application Form Cognitive Skills",
          permission: APPLICATION_FORM_COGNITIVE_SKILLS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-cognitive-skill-index" */ "@/views/dropdown/ApplicationFormCognitiveSkill.vue"
          ),
      },
      {
        path: "/application-form-come-from",
        name: "ApplicationFormComeFromIndex",
        meta: {
          title: "Application Form Come From Options",
          permission: APPLICATION_FORM_COME_FROM_OPTIONS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-come-from-index" */ "@/views/dropdown/ApplicationFormComeFrom.vue"
          ),
      },
      {
        path: "/application-form-common-answers",
        name: "ApplicationFormCommonAnswerIndex",
        meta: {
          title: "Application Form Common Answers",
          permission: APPLICATION_FORM_COMMON_ANSWERS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-common-answer-index" */ "@/views/dropdown/ApplicationFormCommonAnswer.vue"
          ),
      },
      {
        path: "/application-form-completed-by",
        name: "ApplicationFormCompletedByIndex",
        meta: {
          title: "Application Form Completed By Options",
          permission: APPLICATION_FORM_COMPLETED_BY_OPTIONS,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-completed-by-index" */ "@/views/dropdown/ApplicationFormCompletedBy.vue"
          ),
      },
      {
        path: "/application-form-discount-names",
        name: "ApplicationFormDiscountNameIndex",
        meta: {
          title: "Application Form Discount Names",
          permission: APPLICATION_FORM_DISCOUNT_NAMES,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-discount-name-index" */ "@/views/dropdown/ApplicationFormDiscountName.vue"
          ),
      },
      {
        path: "/application-form-discount-types",
        name: "ApplicationFormDiscountTypeIndex",
        meta: {
          title: "Application Form Discount Types",
          permission: APPLICATION_FORM_DISCOUNT_TYPES,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-discount-type-index" */ "@/views/dropdown/ApplicationFormDiscountType.vue"
          ),
      },
      {
        path: "/application-form-educational-placements",
        name: "ApplicationFormEducationalPlacementIndex",
        meta: {
          title: "Application Form Educational Placements",
          permission: APPLICATION_FORM_EDUCATIONAL_PLACEMENTS,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-educational-placement-index" */ "@/views/dropdown/ApplicationFormEducationalPlacement.vue"
          ),
      },
      {
        path: "/application-form-guides",
        name: "ApplicationFormGuideIndex",
        meta: {
          title: "Application Form Guides",
          permission: APPLICATION_FORM_GUIDES,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-guide-index" */ "@/views/dropdown/ApplicationFormGuide.vue"
          ),
      },
      {
        path: "/application-form-hand-preference-abilities",
        name: "ApplicationFormHandPreferenceAbilityIndex",
        meta: {
          title: "Application Form Hand Preference Abilities",
          permission: APPLICATION_FORM_HAND_PREFERENCE_ABILITIES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-hand-preference-ability-index" */ "@/views/dropdown/ApplicationFormHandPreferenceAbility.vue"
          ),
      },
      {
        path: "/application-form-hand-preference-hands",
        name: "ApplicationFormHandPreferenceHandIndex",
        meta: {
          title: "Application Form Hand Preference Hands",
          permission: APPLICATION_FORM_HAND_PREFERENCE_HANDS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-hand-preference-hand-index" */ "@/views/dropdown/ApplicationFormHandPreferenceHand.vue"
          ),
      },
      {
        path: "/application-form-health-ears",
        name: "ApplicationFormHealthEarIndex",
        meta: {
          title: "Application Form Health Ears",
          permission: APPLICATION_FORM_HEALTH_EARS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-ear-index" */ "@/views/dropdown/ApplicationFormHealthEar.vue"
          ),
      },
      {
        path: "/application-form-health-eye-diseases",
        name: "ApplicationFormHealthEyeDiseaseIndex",
        meta: {
          title: "Application Form Health Eye Diseases",
          permission: APPLICATION_FORM_HEALTH_EYE_DISEASES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-eye-disease-index" */ "@/views/dropdown/ApplicationFormHealthEyeDisease.vue"
          ),
      },
      {
        path: "/application-form-health-food-allergies",
        name: "ApplicationFormHealthFoodAllergyIndex",
        meta: {
          title: "Application Form Health Food Allergies",
          permission: APPLICATION_FORM_HEALTH_FOOD_ALLERGIES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-food-allergy-index" */ "@/views/dropdown/ApplicationFormHealthFoodAllergy.vue"
          ),
      },
      {
        path: "/application-form-health-products",
        name: "ApplicationFormHealthProductIndex",
        meta: {
          title: "Application Form Health Products",
          permission: APPLICATION_FORM_HEALTH_PRODUCTS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-product-index" */ "@/views/dropdown/ApplicationFormHealthProduct.vue"
          ),
      },
      {
        path: "/application-form-health-specialists",
        name: "ApplicationFormHealthSpecialistIndex",
        meta: {
          title: "Application Form Health Specialists",
          permission: APPLICATION_FORM_HEALTH_SPECIALISTS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-specialist-index" */ "@/views/dropdown/ApplicationFormHealthSpecialist.vue"
          ),
      },
      {
        path: "/application-form-health-time-periods",
        name: "ApplicationFormHealthTimePeriodIndex",
        meta: {
          title: "Application Form Health TIme Periods",
          permission: APPLICATION_FORM_HEALTH_TIME_PERIODS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-health-time-period-index" */ "@/views/dropdown/ApplicationFormHealthTimePeriod.vue"
          ),
      },
      {
        path: "/application-form-info-types",
        name: "ApplicationFormInfoTypeIndex",
        meta: {
          title: "Application Form Info Types",
          permission: APPLICATION_FORM_INFO_TYPES,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-info-type-index" */ "@/views/dropdown/ApplicationFormInfoType.vue"
          ),
      },
      {
        path: "/application-form-language-skills",
        name: "ApplicationFormLanguageSkillIndex",
        meta: {
          title: "Application Form Language Skills",
          permission: APPLICATION_FORM_LANGUAGE_SKILLS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-language-skill-index" */ "@/views/dropdown/ApplicationFormLanguageSkill.vue"
          ),
      },
      {
        path: "/application-form-math-skills",
        name: "ApplicationFormMathSkillIndex",
        meta: {
          title: "Application Form Math Skills",
          permission: APPLICATION_FORM_MATH_SKILLS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-math-skill-index" */ "@/views/dropdown/ApplicationFormMathSkill.vue"
          ),
      },
      {
        path: "/application-form-physical-motor-skill-disorders",
        name: "ApplicationFormPhysicalMotorSkillDisorderIndex",
        meta: {
          title: "Application Form Physical Motor Skill Disorders",
          permission: APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-physical-motor-skill-disorder-index" */ "@/views/dropdown/ApplicationFormPhysicalMotorSkillDisorder.vue"
          ),
      },
      {
        path: "/application-form-statuses",
        name: "ApplicationFormStatusIndex",
        meta: {
          title: "Application Form Statuses",
          permission: APPLICATION_FORM_STATUSES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-status-index" */ "@/views/dropdown/ApplicationFormStatus.vue"
          ),
      },
      {
        path: "/application-form-types",
        name: "ApplicationFormTypeIndex",
        meta: {
          title: "Application Form Types",
          permission: APPLICATION_FORM_TYPES,
        },
        props: (route) => idNameCategoryProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-type-index" */ "@/views/dropdown/ApplicationFormType.vue"
          ),
      },
      {
        path: "/chapters",
        name: "ChapterIndex",
        meta: { title: "Chapters", permission: CHAPTERS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "chapter-index" */ "@/views/dropdown/Chapter.vue"
          ),
      },
      {
        path: "/client-live-with",
        name: "ClientLiveWithIndex",
        meta: {
          title: "Client Live With Options",
          permission: CLIENT_LIVE_WITH_OPTIONS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "client-live-with-index" */ "@/views/dropdown/ClientLiveWith.vue"
          ),
      },
      {
        path: "/client-statuses",
        name: "ClientStatusIndex",
        meta: { title: "Client Statuses", permission: CLIENT_STATUSES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "client-status-index" */ "@/views/dropdown/ClientStatus.vue"
          ),
      },
      {
        path: "/contacts",
        name: "ContactIndex",
        meta: { title: "Contacts", permission: CONTACTS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "contact-index" */ "@/views/dropdown/Contact.vue"
          ),
      },
      {
        path: "/course-statuses",
        name: "CourseStatusIndex",
        meta: { title: "Course Statuses", permission: COURSE_STATUSES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "contact-index" */ "@/views/dropdown/CourseStatus.vue"
          ),
      },
      {
        path: "/days",
        name: "DayIndex",
        meta: { title: "Days", permission: DAYS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "day-index" */ "@/views/dropdown/Day.vue"
          ),
      },
      {
        path: "/developmental-profile-categories",
        name: "DevelopmentalProfileCategoryIndex",
        meta: {
          title: "Developmental Profile Categories",
          permission: DEVELOPMENTAL_PROFILE_CATEGORIES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-category-index" */ "@/views/dropdown/DevelopmentalProfileCategory.vue"
          ),
      },
      {
        path: "/developmental-profile-descriptions",
        name: "DevelopmentalProfileDescriptionIndex",
        meta: {
          title: "Developmental Profile Descriptions",
          permission: DEVELOPMENTAL_PROFILE_DESCRIPTIONS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-description-index" */ "@/views/dropdown/DevelopmentalProfileDescription.vue"
          ),
      },
      {
        path: "/document-types",
        name: "DocumentTypeIndex",
        meta: { title: "Document Types", permission: DOCUMENT_TYPES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "document-type-index" */ "@/views/dropdown/DocumentType.vue"
          ),
      },
      {
        path: "/educations",
        name: "EducationIndex",
        meta: { title: "Eucations", permission: EDUCATIONS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "education-index" */ "@/views/dropdown/Education.vue"
          ),
      },
      {
        path: "/evaluation-types",
        name: "EvaluationTypeIndex",
        meta: { title: "Evaluation Types", permission: EVALUATION_TYPES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "evaluation-type-index" */ "@/views/dropdown/EvaluationType.vue"
          ),
      },
      {
        path: "/evaluation-video-tags",
        name: "EvaluationVideoTagIndex",
        meta: {
          title: "Evaluation Video Tags",
          permission: EVALUATION_VIDEO_TAGS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "evaluation-video-tag-index" */ "@/views/dropdown/EvaluationVideoTag.vue"
          ),
      },
      {
        path: "/family-statuses",
        name: "FamilyStatusIndex",
        meta: { title: "Family Statuses", permission: FAMILY_STATUSES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "family-status-index" */ "@/views/dropdown/FamilyStatus.vue"
          ),
      },
      {
        path: "/heard-from",
        name: "HeardFromIndex",
        meta: { title: "Heard From Options", permission: HEARD_FROM_OPTIONS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "heard-from-index" */ "@/views/dropdown/HeardFrom.vue"
          ),
      },
      {
        path: "/hours",
        name: "HourIndex",
        meta: { title: "Hours", permission: HOURS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "hour-index" */ "@/views/dropdown/Hour.vue"
          ),
      },
      {
        path: "/languages",
        name: "LanguageIndex",
        meta: { title: "Languages", permission: LANGUAGES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "language-index" */ "@/views/dropdown/Language.vue"
          ),
      },
      {
        path: "/limbo-archive-reasons",
        name: "LimboArchiveReasonIndex",
        meta: {
          title: "Limbo Archive Reasons",
          permission: LIMBO_ARCHIVE_REASONS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "limbo-archive-reason-index" */ "@/views/dropdown/LimboArchiveReason.vue"
          ),
      },
      {
        path: "/minutes",
        name: "MinuteIndex",
        meta: { title: "Minutes", permission: MINUTES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "minute-index" */ "@/views/dropdown/Minute.vue"
          ),
      },
      {
        path: "/months",
        name: "MonthIndex",
        meta: { title: "Months", permission: MONTHS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "month-index" */ "@/views/dropdown/Month.vue"
          ),
      },
      {
        path: "/program-codes",
        name: "ProgramCodeIndex",
        meta: { title: "Program Codes", permission: PROGRAM_CODES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "program-code-index" */ "@/views/dropdown/ProgramCode.vue"
          ),
      },
      {
        path: "/program-types",
        name: "ProgramTypeIndex",
        meta: { title: "Program Types", permission: PROGRAM_TYPES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "program-type-index" */ "@/views/dropdown/ProgramType.vue"
          ),
      },
      {
        path: "/re-evaluation-assistants",
        name: "ReEvaluationAssistantIndex",
        meta: {
          title: "Re-Evaluation Assistants",
          permission: RE_EVALUATION_ASSISTANTS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "re-evaluation-assistant-index" */ "@/views/dropdown/ReEvaluationAssistant.vue"
          ),
      },
      {
        path: "/re-evaluation-processing-activity-types",
        name: "ReEvaluationProcessingActivityTypeIndex",
        meta: {
          title: "Re-Evaluation Processing Activity Types",
          permission: RE_EVALUATION_PROCESSING_ACTIVITY_TYPES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "re-evaluation-processing-activity-type-index" */ "@/views/dropdown/ReEvaluationProcessingActivityType.vue"
          ),
      },
      {
        path: "/re-evaluation-statuses",
        name: "ReEvaluationStatusIndex",
        meta: {
          title: "Re-Evaluation Statuses",
          permission: RE_EVALUATION_STATUSES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "re-evaluation-status-index" */ "@/views/dropdown/ReEvaluationStatus.vue"
          ),
      },
      {
        path: "/re-evaluation-time-periods",
        name: "ReEvaluationTimePeriodIndex",
        meta: {
          title: "Re-Evaluation Time Periods",
          permission: RE_EVALUATION_TIME_PERIODS,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "re-evaluation-time-period-index" */ "@/views/dropdown/ReEvaluationTimePeriod.vue"
          ),
      },
      {
        path: "/report-statuses",
        name: "ReportStatusIndex",
        meta: { title: "Report Statuses", permission: REPORT_STATUSES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "report-status-index" */ "@/views/dropdown/ReportStatus.vue"
          ),
      },
      {
        path: "/report-types",
        name: "ReportTypeIndex",
        meta: { title: "Report Types", permission: REPORT_TYPES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "report-type-index" */ "@/views/dropdown/ReportType.vue"
          ),
      },
      {
        path: "/schedule-types",
        name: "ScheduleTypeIndex",
        meta: {
          title: "Upcoming Evaluation Types",
          permission: SCHEDULE_TYPES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "schedule-type-index" */ "@/views/dropdown/ScheduleType.vue"
          ),
      },
      {
        path: "/time-periods",
        name: "TimePeriodIndex",
        meta: { title: "Time Periods", permission: TIME_PERIODS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "time-period-index" */ "@/views/dropdown/TimePeriod.vue"
          ),
      },
      {
        path: "/dropped-statuses",
        name: "DroppedStatusIndex",
        meta: { title: "Why Dropped Reasons", permission: WHY_DROPPED_REASONS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "dropped-status-index" */ "@/views/dropdown/DroppedStatus.vue"
          ),
      },
      {
        path: "/themes",
        name: "ThemeIndex",
        meta: { title: "Themes", permission: THEMES },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "theme-index" */ "@/views/dropdown/Theme.vue"
          ),
      },
      {
        path: "/upcoming-evaluation-time-zones",
        name: "UpcomingEvaluationTimeZoneIndex",
        meta: {
          title: "Upcoming Evaluation Time Zones",
          permission: UPCOMING_EVALUATION_TIME_ZONES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "upcoming-evaluation-time-zone-index" */ "@/views/dropdown/UpcomingEvaluationTimeZone.vue"
          ),
      },
    ],
  },
];

export default dropdownRoutes;
