<template>
  <nav
    class="sticky top-0 z-10"
    :class="{
      'bg-gray-800': isDark,
      'bg-teal-600': isLight,
      'bg-pink-600': isColorful,
    }"
  >
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center lg:hidden">
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click.stop="$store.dispatch('toggleMobileDropdown')"
          >
            <span class="sr-only">Open main menu</span>
            <BaseHamburgerIcon
              class="block h-6 w-6"
              :class="[mobileHidden ? '' : 'hidden']"
            />

            <BaseHamburgerCloseIcon
              class="h-6 w-6"
              :class="[mobileHidden ? 'hidden' : '']"
            />
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block h-8 w-auto cursor-pointer"
              src="@/assets/images/logo.png"
              alt="Logo"
            />
          </div>
          <div class="hidden lg:block lg:ml-6">
            <div class="flex items-center space-x-4">
              <template
                v-for="navLink in navLinks"
                :key="`nav-link-${navLink.name}`"
              >
                <template v-if="hasPermission(navLink.permission)">
                  <div
                    v-if="navLink.children"
                    class="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0"
                  >
                    <div class="ml-3 relative">
                      <div>
                        <button
                          type="button"
                          class="inline-flex"
                          :class="navLinkClass"
                          id="user-menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                          @click.stop="$store.dispatch('toggle' + navLink.name)"
                        >
                          {{ navLink.name }}

                          <BaseChevronDownIcon
                            class="h-5 w-5 group-hover:text-gray-500"
                          />
                        </button>
                      </div>
                      <div
                        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dropdown"
                        :class="isDropdownHidden(navLink)"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu-button"
                        tabindex="-1"
                      >
                        <template
                          v-for="child in navLink.children"
                          :key="`nav-link-child-${child.name}`"
                        >
                          <router-link
                            class="inline-flex w-full justify-start items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            tabindex="-1"
                            :to="child.to"
                            v-if="hasPermission(child.permission)"
                          >
                            {{ child.name }}
                          </router-link>
                        </template>
                      </div>
                    </div>
                  </div>

                  <router-link :class="navLinkClass" :to="navLink.to" v-else>
                    {{ navLink.name }}
                  </router-link>
                </template>
              </template>
              <div v-if="loginAs" :class="navLinkClass" @click="logout">
                Back to Profile
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0"
        >
          <div class="ml-3 relative">
            <div>
              <button
                type="button"
                class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                @click.stop="$store.dispatch('toggleProfileDropdown')"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  v-show="isLoaded"
                  class="h-8 w-8 rounded-full object-cover"
                  :src="photo"
                  alt="Profile Image"
                  @load="onImageLoad"
                />
                <section
                  v-show="!isLoaded"
                  class="h-8 w-8 rounded-full bg-gray-300 animate-pulse"
                ></section>
              </button>
            </div>
            <div
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="[hidden ? 'hidden' : '']"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <button
                class="inline-flex w-full justify-start items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                @click="logout"
              >
                <BaseLogoutIcon
                  class="fill-current h-5 w-5 text-gray-400 mr-2"
                />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="lg:hidden"
      :class="[mobileHidden ? 'hidden' : '']"
      id="mobile-menu"
    >
      <div class="px-2 pt-2 pb-3 space-y-1">
        <template v-for="navLink in navLinks" :key="navLink.name">
          <router-link
            :class="navLinkMobileClass"
            :to="navLink.to"
            v-if="hasPermission(navLink.permission)"
          >
            {{ navLink.name }}
          </router-link>
        </template>
        <div v-if="loginAs" :class="navLinkMobileClass" @click="logout">
          Back to Profile
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  DASHBOARD,
  FAMILIES,
  MANAGE_FAMILIES,
  MY_FAMILIES,
  CREATE_FAMILY,
  LIMBO_FAMILIES,
  EXPORT_FAMILIES,
  CLIENTS,
  MANAGE_CLIENTS,
  MY_CLIENTS,
  CLIENT_SCHEDULING,
  UNASSIGNED_CLIENTS,
  RE_EVALUATION_FORMS,
  NEW_EVALUATION_VIDEOS,
  ALL_UPLOADED_VIDEOS,
  APPLICATION_FORMS,
  INTERVIEW_LIST,
  EXPORT_CLIENTS,
  ACTIVITIES,
  COURSES,
  DROPDOWNS,
  STAFF,
  SYSTEM,
  REPORTS,
  RBAC,
  REFERRAL_CODES_USAGE,
} from "@/rbac/links.js";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoaded: false,

      navLinks: [
        {
          name: "Dashboard",
          to: { name: "ProfileView" },
          permission: DASHBOARD,
        },
        {
          name: "Families",
          to: { name: "MyFamilyIndex" },
          permission: FAMILIES,
          children: [
            {
              name: "Manage Families",
              to: { name: "FamilyIndex" },
              permission: MANAGE_FAMILIES,
            },
            {
              name: "My Families",
              to: { name: "MyFamilyIndex" },
              permission: MY_FAMILIES,
            },
            {
              name: "Create Family",
              to: { name: "FamilyCreate" },
              permission: CREATE_FAMILY,
            },
            {
              name: "Limbo Families",
              to: { name: "LimboIndex" },
              permission: LIMBO_FAMILIES,
            },
            {
              name: "Export Families",
              to: { name: "FamilyExport" },
              permission: EXPORT_FAMILIES,
            },
          ],
        },
        {
          name: "Clients",
          to: { name: "ClientIndex" },
          permission: CLIENTS,
          children: [
            {
              name: "Manage Clients",
              to: { name: "ClientIndex" },
              permission: MANAGE_CLIENTS,
            },
            {
              name: "My Clients",
              to: { name: "MyClientIndex" },
              permission: MY_CLIENTS,
            },
            {
              name: "Unassigned Clients",
              to: { name: "UnassignedClientIndex" },
              permission: UNASSIGNED_CLIENTS,
            },
            {
              name: "Re-Evaluation Forms",
              to: { name: "ReEvaluationIndex" },
              permission: RE_EVALUATION_FORMS,
            },
            {
              name: "New Evaluation Videos",
              to: { name: "EvaluationNewVideoIndex" },
              permission: NEW_EVALUATION_VIDEOS,
            },
            {
              name: "All Uploaded Videos",
              to: { name: "EvaluationVideoIndex" },
              permission: ALL_UPLOADED_VIDEOS,
            },
            {
              name: "Client Scheduling",
              to: { name: "ClientScheduleIndex" },
              permission: CLIENT_SCHEDULING,
            },
            {
              name: "Application Forms",
              to: { name: "ApplicationFormIndex", query: { status_id: 100 } },
              permission: APPLICATION_FORMS,
            },
            {
              name: "Referral Codes Usage",
              to: { name: "ReferralCodesUsageIndex" },
              permission: REFERRAL_CODES_USAGE,
            },
            {
              name: "Interview List",
              to: { name: "InterviewListIndex" },
              permission: INTERVIEW_LIST,
            },
            {
              name: "Export Clients",
              to: { name: "ClientExport" },
              permission: EXPORT_CLIENTS,
            },
          ],
        },
        {
          name: "Activities",
          to: { name: "ActivityIndex" },
          permission: ACTIVITIES,
        },
        {
          name: "Courses",
          to: { name: "AcademyCourseIndex" },
          permission: COURSES,
        },
        {
          name: "Dropdowns",
          to: { name: "AnnouncementTypeIndex" },
          permission: DROPDOWNS,
        },
        { name: "Staff", to: { name: "StaffIndex" }, permission: STAFF },
        {
          name: "System",
          to: { name: "EvaluationRecommendedVideoIndex" },
          permission: SYSTEM,
        },
        {
          name: "Reports",
          to: { name: "ReportHistoryView" },
          permission: REPORTS,
        },
        { name: "RBAC", to: { name: "RoleIndex" }, permission: RBAC },
      ],
    };
  },
  methods: {
    ...mapActions("user", ["logout"]),
    hasPermission(permission) {
      return this.permissions.includes(permission);
    },
    onImageLoad() {
      this.isLoaded = true;
    },
    isDropdownHidden(navlink) {
      let result = "";
      switch (navlink.name) {
        case "Clients":
          result = this.clientHidden ? "hidden" : "";
          break;
        case "Families":
          result = this.familyHidden ? "hidden" : "";
          break;
      }
      return result;
    },
  },
  computed: {
    navLinkMobileClass() {
      let textClass = "";

      if (this.isDark) {
        textClass = "text-gray-300 dark hover:bg-gray-700";
      }

      if (this.isLight) {
        textClass = "text-white light";
      }

      if (this.isColorful) {
        textClass = "text-white colorful hover:bg-pink-700";
      }

      return `${textClass} hover:text-white block px-3 py-2 rounded-md text-base font-medium`;
    },
    navLinkClass() {
      let textClass = "";

      if (this.isDark) {
        textClass = "text-gray-300 dark hover:bg-gray-700";
      }

      if (this.isLight) {
        textClass = "text-white light";
      }

      if (this.isColorful) {
        textClass = "text-white colorful hover:bg-pink-700";
      }

      return `${textClass} hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer`;
    },
    navLinkActiveClass() {
      return "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer";
    },
    navLinkActiveMobileClass() {
      return "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium";
    },
    ...mapGetters("user", [
      "photo",
      "permissions",
      "isLight",
      "isDark",
      "isColorful",
    ]),
    ...mapState("user", ["loginAs"]),
    ...mapState(["mobileHidden", "hidden", "familyHidden", "clientHidden"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.active.dark {
  &:hover {
    background: $slate-900;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background: $slate-900;
}

.dropdown > .active.dark {
  &:hover {
    background: $slate-900;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0 !important;
  cursor: pointer;
  background: $slate-900;
}

.active.light {
  &:hover {
    background: $teal-800;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background: $teal-800;
}

.dropdown > .active.light {
  &:hover {
    background: $teal-800;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0 !important;
  cursor: pointer;
  background: $teal-800;
}

.active.colorful {
  &:hover {
    background: $pink-800;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background: $pink-800;
}

.dropdown > .active.colorful {
  &:hover {
    background: $pink-800;
    color: $white;
    font-weight: bold;
  }

  color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0 !important;
  cursor: pointer;
  background: $pink-800;
}

.light:hover {
  background: $teal-700;
}
</style>
