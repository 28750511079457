<template>
  <div class="bg-white shadow-md overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <slot name="title" />
    </div>

    <div class="border-t border-gray-200">
      <dl>
        <slot />
      </dl>
    </div>
  </div>
</template>
