<template>
  <button
    type="button"
    class="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
}
</style>
