<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto">
      <div class="align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <slot name="header" />
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <slot />
            </tbody>
          </table>
          <slot name="pagination" />
        </div>
      </div>
    </div>
    <slot name="loader" />
  </div>
</template>
